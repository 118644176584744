import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardJSIcon(props) {
  return (
    <SvgIcon viewBox="0 0 36 36" sx={{ width: 36, height: 36 }} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="15" fill="#4520AB" />
        <path
          d="M17.104 12.16L17.088 19.12C17.088 19.9307 16.96 20.6667 16.704 21.328C16.448 21.9893 16.048 22.5227 15.504 22.928C14.9707 23.3227 14.2987 23.52 13.488 23.52C12.2827 23.52 11.3973 23.1147 10.832 22.304C10.2773 21.4933 10 20.4587 10 19.2H11.184C11.184 20.1387 11.3653 20.896 11.728 21.472C12.0907 22.048 12.6773 22.336 13.488 22.336C14.2453 22.336 14.8213 22.0267 15.216 21.408C15.6107 20.7787 15.808 19.9893 15.808 19.04V13.344H10.832V12.16H17.104Z"
          fill="#29EDFF"
        />
        <path
          d="M24.5857 17.776C25.2364 18.1067 25.7164 18.4907 26.0258 18.928C26.3458 19.3547 26.5058 19.8987 26.5058 20.56C26.5058 21.4453 26.1964 22.16 25.5778 22.704C24.9698 23.248 24.0844 23.52 22.9218 23.52C22.1004 23.52 21.3271 23.344 20.6017 22.992C19.8871 22.64 19.3431 22.128 18.9697 21.456L19.8657 20.672C20.1537 21.1947 20.5751 21.6053 21.1297 21.904C21.6844 22.192 22.2817 22.336 22.9218 22.336C23.6897 22.336 24.2657 22.176 24.6497 21.856C25.0337 21.5253 25.2257 21.104 25.2257 20.592C25.2257 20.2187 25.1298 19.8987 24.9377 19.632C24.7457 19.3653 24.4257 19.1147 23.9777 18.88L21.1137 17.44C20.4524 17.1093 19.9831 16.7253 19.7057 16.288C19.4391 15.8507 19.3057 15.328 19.3057 14.72C19.3057 14.1973 19.4551 13.7333 19.7537 13.328C20.0524 12.912 20.4738 12.5867 21.0177 12.352C21.5724 12.1173 22.2017 12 22.9058 12C23.6418 12 24.3084 12.1387 24.9058 12.416C25.5138 12.6933 26.0204 13.104 26.4258 13.648L25.5457 14.448C25.2577 14.0533 24.8738 13.744 24.3938 13.52C23.9244 13.296 23.4284 13.184 22.9058 13.184C22.2017 13.184 21.6364 13.3387 21.2097 13.648C20.7937 13.9573 20.5857 14.3307 20.5857 14.768C20.5857 15.12 20.6711 15.4187 20.8417 15.664C21.0124 15.9093 21.3004 16.128 21.7057 16.32L24.5857 17.776Z"
          fill="#29EDFF"
        />
      </svg>
    </SvgIcon>
  );
}
