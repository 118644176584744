import React from 'react';
import { SvgIcon } from '@mui/material';

export default function LocateIcon({ sx, ...props }) {
  const firstColor = props.color || '#4520AB';
  const secondColor = '#ffffff';
  return (
    <SvgIcon
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 28, height: 28, ...sx }}
      {...props}
    >
      <rect width="28" height="28" rx="8" fill={firstColor} />
      <path
        d="M14.0003 14.6667C15.1049 14.6667 16.0003 13.7713 16.0003 12.6667C16.0003 11.5621 15.1049 10.6667 14.0003 10.6667C12.8958 10.6667 12.0003 11.5621 12.0003 12.6667C12.0003 13.7713 12.8958 14.6667 14.0003 14.6667Z"
        stroke={secondColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0003 20.6667C16.667 18 19.3337 15.6122 19.3337 12.6667C19.3337 9.72119 16.9458 7.33337 14.0003 7.33337C11.0548 7.33337 8.66699 9.72119 8.66699 12.6667C8.66699 15.6122 11.3337 18 14.0003 20.6667Z"
        stroke={secondColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
