import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const useStudyToolsQuery = () => {
  const { allFile } = useStaticQuery(graphql`
    query studyToolsQuery {
      allFile(filter: { relativeDirectory: { regex: "/studyTools/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  const studyToolsImg = getImageDataMapByFileEdges(allFile.edges);
  return { logosData: studyToolsImg };
};
